<template>
    <div>
         <a-table bordered  :columns="columns" :data-source="data">
            <a slot="name" slot-scope="text">{{ text }}</a>
            <span slot="action">
            <a>
                <a-tooltip>
                    <template slot="title">
                        检索
                    </template>
                    <router-link :to="{name:'s_searchresult'}"><a-icon type="search" /></router-link>
                </a-tooltip>
            </a>
            <a-divider type="vertical"/>
            <a>
                 <a-tooltip>
                    <template slot="title">
                        删除
                    </template>
                    <a-icon type="delete" />
                </a-tooltip>
                
            </a>
            </span>
        </a-table>
    </div>
</template>

<script>
const data = [
  {
    key: '1',
    serialnum: '1',
    title:'5-10月发动机',
    search: "ti='发动机' and pd <202010 and pd>202005",
    result: '1000条',
    time: '2020-11-16'
  },
  {
    key: '2',
    serialnum: '2',
    title:'2020年发动机',
    search: "(ti='发动机' ) and pd =2020",
    result: '350条',
    time: '2020-11-16'
  },
  {
    key: '3',
    serialnum: '3',
    title:'国家电网',
    search: "国家电网",
    result: '8000条',
    time: '2020-11-16'
  },
];
export default {
  name:'MySearchTable',
  props:["columns"],
  data() {
    return {
      data
    };
  }
};
</script>

<style scoped>
   
</style>