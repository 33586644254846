<template>
    <div>
        <a-form-model layout="inline" :model="form" @submit="handleSubmit" @submit.native.prevent>
            <a-form-model-item label="名称">
                <a-input v-model="form.name" />
            </a-form-model-item>
           <a-form-model-item label="创建时间">
           <a-range-picker v-model="form.date"  />
            </a-form-model-item>
              <a-form-model-item>
                <a-button  type="primary" html-type="submit"> 搜索</a-button>
                <a-button style="margin-left: 10px;" @click="resetForm">重置</a-button>
            </a-form-model-item>
        </a-form-model>    
    </div>
</template>

<script>
    export default {
        name:"HeadSearch",
        data() {
            return {
                form:{
                    name:'',
                    date:''
                }
            }
        },
        methods: {
            handleSubmit(){
                console.log("ok");
            },
            resetForm(){
                this.form.name = "";
                this.form.date = "";
            }
        },
    }
</script>

<style lang="scss" scoped>
</style>